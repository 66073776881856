import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/categories',
    name: 'Categorias',
    component: () => import('@/views/categories/List_Categories.vue'),
  },
  {
    path: '/form_category/',
    name: 'Form Categoria',
    component: () => import('@/views/categories/FormCategory.vue'),
    children: [{
      path: ':id_category', 
      name: 'Editar Categoria', 
    component: () => import('@/views/categories/FormCategory.vue')
    }],
  },
  {
    path: '/cupons',
    name: 'Cupons',
    component: () => import('@/views/cupons/List_Cupons.vue'),
  },
  {
    path: '/form_cupon/',
    name: 'Form Cupom',
    component: () => import('@/views/cupons/FormCupons.vue'),
    children: [{
      path: ':id_cupon', 
      name: 'Editar Cupom', 
    component: () => import('@/views/cupons/FormCupons.vue')
    }],
  },
  {
    path: '/discounts',
    name: 'Descontos',
    component: () => import('@/views/discounts/List_Discounts.vue'),
  },
  {
    path: '/form_discount/',
    name: 'Form Desconto',
    component: () => import('@/views/discounts/FormDiscount.vue'),
    children: [{
      path: ':id_discount', 
      name: 'Editar Desconto', 
      component: () => import('@/views/discounts/FormDiscount.vue')
    }],
  },
  {
    path: '/payments',
    name: 'Pagamentos',
    component: () => import('@/views/payments/List_Payments.vue'),
  },
  {
    path: '/form_payment/',
    name: 'Form Pagamento',
    component: () => import('@/views/payments/FormPayment.vue'),
    children: [{
      path: ':id_payment', 
      name: 'Editar Pagamento', 
      component: () => import('@/views/payments/FormPayment.vue')
    }],
  },
  {
    path: '/periods',
    name: 'Períodos',
    component: () => import('@/views/periods/List_Periods.vue'),
  },
  {
    path: '/form_period/',
    name: 'Form Período',
    component: () => import('@/views/periods/FormPeriod.vue'),
    children: [{
      path: ':id_period', 
      name: 'Editar Período', 
      component: () => import('@/views/periods/FormPeriod.vue')
    }],
  },
  {
    path: '/plans',
    name: 'Planos',
    component: () => import('@/views/plans/List_Plans.vue'),
  },
  {
    path: '/form_plan/',
    name: 'Form Plano',
    component: () => import('@/views/plans/FormPlan.vue'),
    children: [{
      path: ':id_plan', 
      name: 'Editar Plano', 
      component: () => import('@/views/plans/FormPlan.vue')
    }],
  },
  {
    path: '/subscriptions',
    name: 'Assinaturas',
    component: () => import('@/views/subscriptions/List_Subscriptions'),
  },
  {
    path: '/form_subscription/',
    name: 'Form Assinatura',
    component: () => import('@/views/subscriptions/FormSubscription.vue'),
    children: [{
      path: ':id_subscription', 
      name: 'Editar Assinatura', 
      component: () => import('@/views/subscriptions/FormSubscription.vue')
    }],
  },
  {
    path: '/users',
    name: 'Usuários',
    component: () => import('@/views/users/List_Users.vue'),
  },
  {
    path: '/form_user/',
    name: 'Form Usuário',
    component: () => import('@/views/users/FormUser.vue'),
    children: [{
      path: ':id_user', 
      name: 'Editar Usuário', 
      component: () => import('@/views/users/FormUser.vue')
    }],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.name} | ${process.env.VUE_APP_TITLE}`
  window.scrollTo(0, 0);
  next()
})

export default router
