
import CuponService from '../../services/CuponService'

const state = () => ({
    loadingCupons: false,
    cupon: null,
    cupons: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_cupon: false
})
  
const mutations = {
    SET_CUPON: (state, payload) => {
        state.cupon = payload
    },
    SET_CUPONS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.cupons = payload.items
        }else{
            state.cupons = [...state.cupons, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_CUPONS: (state, payload) => {
        state.loadingCupon = payload
    },
    SET_SHOW_MODAL_CUPON: (state, payload) => {
        state.show_modal_new_cupon = payload
    },
}

const actions = {
    async fetchCupon({commit}, id){
        try{
            commit('SET_LOADING_CUPONS', true)
            const response = await CuponService.getOne(id);
            commit('SET_CUPON', response.data)
            commit('SET_LOADING_CUPONS', false)
        } catch (error) {
            commit('SET_LOADING_CUPONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCupons({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_CUPONS', true)
            const response = await CuponService.get(keyword, pageNumber, order);
            commit('SET_CUPONS', response.data)
            commit('SET_LOADING_CUPONS', false)
        } catch (error) {
            commit('SET_LOADING_CUPONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCuponsActives({commit}){
        try{
            commit('SET_LOADING_CUPONS', true)
            const  keyword = '';
            const  pageNumber = 1;
            const  pageSize = 1000;
            const order = { name: 'ID', column: 'ID', sort: 'ASC' };
            const response = await CuponService.get(keyword, pageNumber, pageSize, order);
            commit('SET_CUPONS', response.data)
            commit('SET_LOADING_CUPONS', false)
        } catch (error) {
            commit('SET_LOADING_CUPONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createCupon({commit}, data){
        try{
            commit('SET_LOADING_CUPONS', true)
            const response = await CuponService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_CUPON', false)
            commit('SET_LOADING_CUPONS', false)
        } catch (error) {
            commit('SET_LOADING_CUPONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeCupon({commit}, data){
        try{
            commit('SET_LOADING_CUPONS', true)
            const response = await CuponService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_CUPONS', false)
        } catch (error) {
            commit('SET_LOADING_CUPONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getCupon: state => state.cupon,
    getCupons: state => state.cupons,
    getLoadingCupon: state => state.loadingCupon,
    getShowModalNewCupon: state => state.show_modal_new_cupon
}

export default {
    state,
    getters,
    mutations,
    actions
};